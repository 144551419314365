import { dayjs, DTF } from '@algorh/shared'

import { NotificationType } from '@/core/enums/User'
import { Notification } from '@/core/types/Me'
import { ProjectActivity } from '@/core/types/Project'
import { WorkingSlot } from '@/core/types/Schedule'
import { i18n } from '@/plugins/i18n'

const TIME_BEFORE_ACTIVITY_CHANGE_NOTIFICATION = 6

function getActivityChangeNotification(workingSchedule: WorkingSlot[], projectActivities: ProjectActivity[]): Notification | null {
  const daySchedule = workingSchedule
    .filter((slot: WorkingSlot) => dayjs(slot.real_start).isSame(dayjs(), 'day'))
    .reduce((acc: WorkingSlot[], slot: WorkingSlot) => {
      if (acc.length === 0) {
        return [slot]
      }
      const lastSlot = { ...acc[acc.length - 1] }
      if (
        lastSlot.project_activity_id === slot.project_activity_id
        && lastSlot.activity_category === slot.activity_category
      ) {
        lastSlot.real_end = slot.real_end
        return acc
      }
      return [...acc, slot]
    }, [] as WorkingSlot[])

  if (daySchedule.length === 0) {
    return null
  }
  const [currentSlot, nextSlot] = daySchedule.reduce(
    (acc: WorkingSlot[], slot: WorkingSlot, index: number) => {
      if (dayjs(Date.now()).isBetween(slot.real_start, slot.real_end)) {
        return [slot, daySchedule[index + 1] ?? null]
      }
      return acc
    },
    [] as WorkingSlot[],
  )

  if (nextSlot === null
    || (
      currentSlot?.project_activity_id === nextSlot?.project_activity_id
      && currentSlot?.activity_category === nextSlot?.activity_category
    )
    || nextSlot?.project_activity_id === null
    || dayjs(Date.now()).isBefore(
      dayjs(nextSlot?.real_start).subtract(TIME_BEFORE_ACTIVITY_CHANGE_NOTIFICATION, 'minute'),
    )
  ) {
    return null
  }
  const identifier = btoa(
    `${currentSlot?.real_start}-${nextSlot?.real_start}-${nextSlot?.project_activity_id}`,
  )

  const delay = dayjs(nextSlot?.real_start).diff(dayjs(), 'minute')

  const projectActivity = projectActivities.find(
    (activity) => activity.id === nextSlot?.project_activity_id,
  )

  const message = `${dayjs(nextSlot?.real_start).format(DTF.TIME_SHORT)} - ${dayjs(
    nextSlot?.real_end,
  ).format(DTF.TIME_SHORT)} : ${projectActivity?.name || ''}`

  const subject = i18n.global.t('notifications.Activity change in {delay} minutes', { delay })

  return {
    id: identifier,
    content: {
      subject,
      type: NotificationType.ACTIVITY_CHANGE,
      message,
    },
    created_at: dayjs().format(DTF.DATETIME),
    read_at: null,
  }
}

export { getActivityChangeNotification }
