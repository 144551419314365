import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { storeToRefs } from 'pinia'

import { dayjs, DTF, FetchError, FetchResult } from '@algorh/shared'

import { useAppStore } from '@/appStore'
import { useAlertsStore, useAppInit, useBrowserNotifications, useConfigs } from '@/composables'
import { NotificationType } from '@/core/enums/User'
import { MeApiService } from '@/core/services'
import { Notification } from '@/core/types/Me'
import { queryClient } from '@/queryClient'

import { CalendarCustomerAdvisersApiService } from '../calendar/services'

import { getActivityChangeNotification } from './activityChangeNotification'

const NOTIFICATION_POLLING_DELAY = 2 * 60 * 1000

export function useNotifications() {
  // Composables
  const { projectActivities, permissions } = useAppInit() // @TODO voir si bien filtré

  const { notificationsEnabled } = useConfigs()

  const appStore = useAppStore()

  const router = useRouter()

  const { appLogo } = storeToRefs(appStore)

  const { setAlert, removeAlert } = useAlertsStore()

  const { getBrowserNotifications, showBrowserNotification, requestNotificationPermission } = useBrowserNotifications(`/assets/svg/brands/${appLogo.value}.svg`)

  // Refs
  const activityChangeNotifications = ref<Notification[]>([])
  const readNotificationsLoading = ref<string[]>([])
  const displayNotificationsWarning = ref<boolean>(true)
  const readActivityChangeNotificationsIds = ref<string[]>([])

  // Queries
  const date = computed(() => dayjs().startOf('week').format(DTF.DATE))

  const canFetchNotifications = computed(() => projectActivities.value.data !== null && notificationsEnabled.value)
  const canFetchSchedule = computed(() => canFetchNotifications.value && permissions.value?.access_calendar)

  const { data: schedule, refetch: refetchSchedule } = useQuery({
    enabled: canFetchSchedule,
    queryKey: ['customer-adviser', 'calendar', 'schedule', date],
    queryFn: () => CalendarCustomerAdvisersApiService.getScheduleByDate(date.value),
  })

  const workingSchedule = computed(() => schedule.value?.data?.schedule.working_schedule ?? [])

  const { data: notifications } = useQuery<FetchResult<Notification[]>, FetchError>({
    enabled: canFetchNotifications,
    queryKey: ['notifications'],
    queryFn: getNotifications,
    refetchInterval: NOTIFICATION_POLLING_DELAY,
  })

  // ActivityChange notifications
  const unreadActivityChangeNotifications = computed(() => activityChangeNotifications.value.filter((n) => !readActivityChangeNotificationsIds.value.includes(n.id)) ?? [])
  const readActivityChangeNotifications = computed(() => activityChangeNotifications.value.filter((n) => readActivityChangeNotificationsIds.value.includes(n.id)) ?? [])

  // Base notifications
  const notificationsData = computed(() => notifications.value?.data ?? [])
  const readNotifications = computed(() => notificationsData.value.filter((n) => n.read_at !== null))
  const unreadNotifications = computed(() => notificationsData.value.filter((n) => n.read_at === null))

  const readPlanningUpdateNotifications = computed(() => readNotifications.value.filter((n) => n.content.type === 'planning_update'))
  const unreadPlanningUpdateNotifications = computed(() => unreadNotifications.value.filter((n) => n.content.type === 'planning_update'))

  const readExchangeNotifications = computed(() => readNotifications.value.filter((n) => n.content.type === 'exchange'))
  const unreadExchangeNotifications = computed(() => unreadNotifications.value.filter((n) => n.content.type === 'exchange'))

  // All notifications
  const allNotifications = computed(() => [...notificationsData.value, ...activityChangeNotifications.value])
  const unreadAllNotifications = computed(() => [...unreadNotifications.value, ...unreadActivityChangeNotifications.value])
  const readAllNotifications = computed(() => [...readNotifications.value, ...readActivityChangeNotifications.value])

  // Methods
  async function getNotifications() {
    if (canFetchSchedule.value) {
      try {
        const activityChange = getActivityChangeNotification(workingSchedule.value, projectActivities.value.data ?? [])
        if (activityChange) {
          activityChangeNotifications.value = [...activityChangeNotifications.value, activityChange]
          await displayActivityChangeNotification(activityChange)
        }
      } catch (e) {
        console.error(e)
      }
    }
    const fetchedNotifications = await MeApiService.getNotifications()
    const knownNotificationsIds = notificationsData.value.map((n) => n.id)
    const newNotifications = fetchedNotifications.data?.filter((n) => n.read_at === null && !knownNotificationsIds.includes(n.id)) ?? []

    if (canFetchSchedule.value && newNotifications.map((n) => n.content.type).includes(NotificationType.PLANNING_UPDATE)) {
      await refetchSchedule()
    }

    displayNotifications(newNotifications)
    return fetchedNotifications
  }

  async function displayNotifications(ns: Notification[]) {
    for (const n of ns) {
      const timestamp = dayjs(n.created_at).valueOf()

      try {
        await showBrowserNotification(n.content.subject, {
          tag: `unread-planning-update-${timestamp}`,
          body: n.content.message,
          data: {
            redirectUrl: `/calendar/${dayjs().startOf('week').format(DTF.DATE)}`,
          },
        })
      } catch (e) {
        console.error(e)
      }

      setAlert(
        {
          variant: 'info',
          title: n.content.subject,
          message: n.content.message,
          autoHide: false,
          closable: true,
          onClose: async () => {
            await markAsReadNotifications([n.id])

            try {
              const notifications = await getBrowserNotifications(`unread-planning-update-${timestamp}`)

              notifications.forEach((notification) => {
                notification.close()
              })
            } catch (e) {
              console.error(e)
            }
          },
        },
        n.id,
      )
    }
  }

  async function displayActivityChangeNotification(nac: Notification) {
    const timestamp = dayjs(nac.created_at).valueOf()

    const redirectUrl = `/calendar/${dayjs().startOf('week').format(DTF.DATE)}`

    try {
      await showBrowserNotification(nac.content.subject, {
        tag: `activity-change-${timestamp}`,
        body: nac.content.message,
        data: { redirectUrl },
      })
    } catch (e) {
      console.error(e)
    }

    markAsReadActivityChangeNotifications([nac.id]) // Pourquoi faire ca ici avant le onClose ??

    await router.push(redirectUrl)

    setAlert({
      variant: 'info',
      title: nac.content.subject,
      message: nac.content.message,
      autoHide: false,
      closable: true,
      onClose: async () => {
        markAsReadActivityChangeNotifications([nac.id])
        try {
          const browserNotifications = await getBrowserNotifications(`activity-change-${timestamp}`)
          for (const bn of browserNotifications) {
            bn.close()
          }
        } catch (e) {
          console.error(e)
        }
      },
    }, nac.id)
  }

  // Methods
  function markAsReadActivityChangeNotifications(uuids: string[]) {
    readActivityChangeNotificationsIds.value = [...uuids, ...readActivityChangeNotificationsIds.value]
  }

  //   const filteredUuids = uuids.filter((uuid) => !readNotificationsLoading.value.includes(uuid))
  const { mutate: markAsReadNotifications, isPending: isPendingMarkingAsRead } = useMutation({
    mutationFn: (uuids: string[]) => MeApiService.putNotificationsAsRead(uuids),
    onMutate(uuids) {
      //     readNotificationsLoading.value = [...readNotificationsLoading.value, ...uuids]
      readNotificationsLoading.value = uuids
    },
    onSuccess: (_, uuids) => {
      const updatedNotifications = notificationsData.value.map((n): Notification => ({ ...n, read_at: uuids.includes(n.id) ? dayjs().format(DTF.DATETIME) : n.read_at }))

      queryClient.setQueryData(['notifications'], {
        ...notifications.value,
        data: updatedNotifications,
      })

      readNotificationsLoading.value = []

      for (const uuid of uuids) {
        removeAlert(uuid)
      }
    },
  })

  const { mutate: deleteNotifications, isPending: isPendingDeleting } = useMutation({
    mutationFn: (uuids: string[]) => MeApiService.deleteNotifications(uuids),
    onMutate(uuids) {
      //     readNotificationsLoading.value = [...readNotificationsLoading.value, ...uuids]
      readNotificationsLoading.value = uuids
    },
    onSuccess: (_, uuids) => {
      const updatedNotifications = notificationsData.value.filter((n) => !uuids.includes(n.id))
      queryClient.setQueryData(['notifications'], {
        ...notifications.value,
        data: updatedNotifications,
      })
      readNotificationsLoading.value = []
    },
  })

  const isMarkingAsReadOrDeleting = computed(() => isPendingDeleting.value || isPendingMarkingAsRead.value)

  function hideNotificationsWarning() {
    displayNotificationsWarning.value = false
  }

  onMounted(async () => {
    if (!canFetchNotifications.value) {
      return
    }
    try {
      await requestNotificationPermission()
    } catch (e) {
      console.error(e)
    }
  })

  return {
    // State
    displayNotificationsWarning,
    isMarkingAsReadOrDeleting,
    // Getters
    allNotifications,
    unreadAllNotifications,
    readAllNotifications,

    readPlanningUpdateNotifications,
    unreadPlanningUpdateNotifications,

    readExchangeNotifications,
    unreadExchangeNotifications,

    readActivityChangeNotifications,
    unreadActivityChangeNotifications,
    // Actions
    markAsReadNotifications,
    markAsReadActivityChangeNotifications,
    deleteNotifications,
    hideNotificationsWarning,
  }
}
